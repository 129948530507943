import * as _Calendar2 from "react-calendar/dist/Calendar.css";

var _Calendar = "default" in _Calendar2 ? _Calendar2.default : _Calendar2;

import _DatePicker2 from "./DatePicker";
import _DatePicker3 from "./DatePicker.css";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
_Calendar;

var _DatePicker = _interopRequireDefault(_DatePicker2);

_DatePicker3;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} // File is created during build phase and placed in dist directory
// eslint-disable-next-line import/no-unresolved


var _default = _DatePicker["default"];
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;